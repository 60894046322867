<template>
  <div class="co-folding-ribbon px-4 text-co-gray-50">
    <span>
      <slot>リボンのテキスト</slot>
    </span>
  </div>
</template>

<style scoped>
.co-folding-ribbon::before {
  position: absolute;
  top: 100%;
  left: 0;
  content: '';
  border-right-style: solid;
  border-right-width: 10px;
  border-bottom: 10px solid transparent;
}

.co-folding-ribbon.bg-co-gray-50::before {
  border-right-color: #ccc !important;
}

.co-folding-ribbon.bg-co-gray-700::before {
  border-right-color: #232323 !important;
}

.co-folding-ribbon.bg-co-primary-500::before {
  border-right-color: #083548 !important;
}

.co-folding-ribbon.bg-co-primary-400::before {
  border-right-color: #164f63 !important; /* Darkened #1f7f96 by 30% */
}

.co-folding-ribbon.bg-co-success-500::before {
  border-right-color: #3d6f3d !important; /* Darkened #5cb85c by 30% */
}

.co-folding-ribbon.bg-co-info-500::before {
  border-right-color: #41788e !important; /* Darkened #5bc0de by 30% */
}

.co-folding-ribbon.bg-co-warning-500::before {
  border-right-color: #b58339 !important; /* Darkened #f0ad4e by 30% */
}

.co-folding-ribbon.bg-co-danger-500::before {
  border-right-color: #a63c36 !important; /* Darkened #d9534f by 30% */
}
</style>

